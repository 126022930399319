// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

// ==============================|| LIGHT BOX ||============================== //
.slick-arrow:before {
    color: $grey500;
}

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
    z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
    .ps__rail-y {
        &:hover > .ps__thumb-y,
        &:focus > .ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }

    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
    &.ps--active-y > .ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;

        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }

    &.ps--scrolling-y > .ps__rail-y,
    &.ps--scrolling-x > .ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }

    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }

    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }

    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }

    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {
    0%,
    50%,
    100% {
        transform: translateY(0px);
    }

    25% {
        transform: translateY(-10px);
    }

    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {
    0%,
    50%,
    100% {
        transform: translateX(0px);
    }

    25% {
        transform: translateX(-10px);
    }

    75% {
        transform: translateX(10px);
    }
}

/* Example Styles for React Tags*/
#app {
    padding: 40px;
}

div.ReactTags__tags {
    position: relative;
}

/* Styles for the input */
div.ReactTags__tagInput {
    width: 200px;
    margin: 10px 0;
    border-radius: 5px;
    display: inline-block;
}

div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 38px;
    margin: 0;
    font-size: 14px;
    width: 100%;
    border-radius: 10px;
    border: 1.5px solid #aaa;
    background-color: #fafafa;
    padding: 0 15px;
}

div.ReactTags__tagInput input.ReactTags__tagInputField::placeholder {
    font-weight: 300;
    color: #acacac;
    font-size: 14px;
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
    border: 1px solid #ddd;
    background: #eee;
    font-size: 12px;
    display: inline-block;
    padding: 5px;
    margin-top: 10px;
    margin-right: 5px;
    cursor: move;
    border-radius: 10px;
}

div.ReactTags__selected button.ReactTags__remove {
    color: #aaa;
    border-width: 0;
    margin-left: 5px;
    cursor: pointer;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
    position: absolute;
}

div.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    background: white;
    width: 200px;
}

div.ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
}

div.ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}

div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
}

.link {
    font-weight: 600;
    height: 38px;
    font-size: 0.875rem;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 122px;
    text-transform: none;
    background-color: #fff;
    border: 2px solid #fff;
    color: #1d90b5;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    border-radius: 2px;
    margin-right: 1.5rem !important;
    text-decoration: none;
}

.link:hover {
    background-color: #e6e6e6;
    border-color: #e6e6e6;
    color: #1d90b5;
}

.link-transparent {
    border: 2px solid #fff;
    font-weight: 600;
    height: 38px;
    font-size: 0.875rem;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 122px;
    text-transform: none;
    background-color: transparent;
    color: #fff;
    margin-right: 1.5rem !important;
    text-decoration: none;
}

.link-transparent:hover {
    background-color: rgba(0, 0, 0, 0.1);
    color: #fff;
}

::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgb(170, 170, 170, 0.5);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    opacity: 0.9;
    background-color: #9e9e9e;
}

.btn-shine-parent {
    position: absolute;
    margin: 0;
    right: 0;
    top: 12px;
    font-size: 0.8125rem;
    cursor: pointer;
    transition: mask-position 2s ease, -webkit-mask-position 2s ease;
    // border-radius: 4px 0 0 4px;
    font-weight: 400;
    background-color: rgb(211, 47, 47);
    color: white;
    padding: 5px;
    font-family: inherit;
    z-index: 0;
}

.btn-shine-parent:after {
    content: '';
    top: 0;
    transform: translateX(100%);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    animation: slide 1s infinite 1s;
    background: -moz-linear-gradient(
        left,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.8) 50%,
        rgba(128, 186, 232, 0) 99%,
        rgba(125, 185, 232, 0) 100%
    ); /* FF3.6+ */
    background: -webkit-gradient(
        linear,
        left top,
        right top,
        color-stop(0%, rgba(255, 255, 255, 0)),
        color-stop(50%, rgba(255, 255, 255, 0.8)),
        color-stop(99%, rgba(128, 186, 232, 0)),
        color-stop(100%, rgba(125, 185, 232, 0))
    ); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(
        left,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.8) 50%,
        rgba(128, 186, 232, 0) 99%,
        rgba(125, 185, 232, 0) 100%
    ); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(
        left,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.8) 50%,
        rgba(128, 186, 232, 0) 99%,
        rgba(125, 185, 232, 0) 100%
    ); /* Opera 11.10+ */
    background: -ms-linear-gradient(
        left,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.8) 50%,
        rgba(128, 186, 232, 0) 99%,
        rgba(125, 185, 232, 0) 100%
    ); /* IE10+ */
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.8) 50%,
        rgba(128, 186, 232, 0) 99%,
        rgba(125, 185, 232, 0) 100%
    );
}

.btn-shine-parent span:nth-child(1)::after {
    content: '';
    position: absolute;
    left: -10px;
    width: 0;
    height: 0;
    bottom: 0;
    border-top: 15px solid transparent;
    border-right: 10px solid rgb(211, 47, 47);
    border-bottom: 0px solid transparent;
}

.btn-shine-parent span:nth-child(2)::after {
    content: '';
    position: absolute;
    left: -10px;
    width: 0;
    height: 0;
    top: 0;
    border-top: 0px solid transparent;
    border-right: 10px solid rgb(211, 47, 47);
    border-bottom: 15px solid transparent;
}

@keyframes slide {
    0% {
        transform: translateX(-65%);
    }
    100% {
        transform: translateX(100%);
    }
}
